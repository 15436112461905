.modeOfDelivery-container {
  background-color: #f4f8fb;
  padding-bottom: 5rem;
  padding-top: 5rem;
}

.modeOfDelivery-container .payment-success-msg {
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
}
.payment-success-msg .payment-success-alert {
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem;
  border-radius: 1.25rem;
  background-color: #d2f5d2;
  color: rgb(66, 184, 81);
  border-color: #23b16e;
}
.image-container{
  display: flex;  
  flex-wrap: wrap;
}
.mod-password{
  position: relative;
}
.mod-show-password{
  position: absolute;
  right: 40px;
  top: 34px;
  font-size: 1.3rem;
}
.mod-password .form-control.is-invalid{
  background-image: none;
}
.payment-success-msg .payment-success-alert .payment-success-icon {
  font-size: 5rem;
  margin-right: 1rem;
}

.modeOfDelivery-heading p {
  color: #606365;
  font-size: 1.5rem;
}

.modeOfDelivery-container .select-modeofDelivery {
  display: flex;
}
.delivery-details-container {
  padding: 32px 30px;
  border: 1px solid #c9c6c6;
  border-radius: 10px;
  background-color: #fafafa;
}
.remove-files-btn{
  padding: 0px;
  margin-left: 5px;
  border: none;
  font-size: 1.5rem;
  color:#ff0000;
  margin-bottom: 4px;
  transition: transform 0.3s ease;
}
.remove-files-btn:hover{
  color: #ff0000;
  transform: scale(1.2);   
}
.upload-files-btn{  
  margin-left: 5px;
  border: none;
  color: #fff;
}
.upload-files-btn:hover{
  color: #fff;
}
.snowflake-mode {
  margin-right: 1rem;
}

.delivery-details-container label,
.snowflake-mode .form-check,
.snowflake-mode label,
.airbyte-mode .form-check,
.airbyte-mode label {
  color: #121416;
}

.snowflake-mode .form-check-input:focus,
.airbyte-mode .form-check-input:focus,
.snowflake-mode .form-check-input:checked,
.airbyte-mode .form-check-input:checked {
  box-shadow: none;
  background-color: rgb(96, 173, 95);
  border-color: #f8f8f8;
}
.delivery-save-btn {
  background-color: #37afe3;
  padding: 7px 50px;
  color: white;

  font-weight: 700;
}

.delivery-save-btn:hover {
  background-color: #3589ad;
  color: white;
}

.mode-of-delivery-btn {
  display: flex;
  justify-content: flex-end;
}

.AirByteServer .form-control:focus,
.AirByteServer .form-select:focus,
.delivery-details-container .form-control:focus,
.delivery-details-container .form-select:focus {
  box-shadow: none;
  border: 1px solid #303030;
}
.delivery-details-container label span {
  color: red;
}
.secound-time-purchase-Airbyte-screen span,
.secound-time-purchase-SnowFlake-screen span,.attachment-document {
  color: #121416;
  font-weight: 700;
}

.secound-time-purchase-Airbyte-screen div,
.secound-time-purchase-SnowFlake-screen div {
  color: #121416;
}

.secound-time-purchase-Airbyte-screen .edit-delete button,
.secound-time-purchase-SnowFlake-screen .edit-delete button,
.secound-time-purchase-Airbyte-screen .edit-delete,
.secound-time-purchase-SnowFlake-screen .edit-delete {
  color: #37afe3;
}

.secound-time-purchase-Airbyte-screen .edit-delete button:focus,
.secound-time-purchase-SnowFlake-screen .edit-delete button:focus {
  box-shadow: none;
}

.secound-time-purchase-Airbyte-screen .edit-delete button:hover,
.secound-time-purchase-SnowFlake-screen .edit-delete button:hover {
  text-decoration: underline;
}
.secound-time-purchase-Airbyte-screen .airbyte-s-btns,
.secound-time-purchase-SnowFlake-screen .snowflake-s-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.secound-time-purchase-Airbyte-screen .airbyte-s-btns span,
.secound-time-purchase-SnowFlake-screen .snowflake-s-btns span {
  color: #37afe3;
}

.secound-time-purchase-Airbyte-screen .airbyte-s-btns .edit-btn,
.secound-time-purchase-Airbyte-screen .airbyte-s-btns .delete-btn,
.secound-time-purchase-SnowFlake-screen .snowflake-s-btns .edit-btn,
.secound-time-purchase-SnowFlake-screen .snowflake-s-btns .delete-btn {
  padding: 0px;
  text-decoration: none;
}

.secound-time-purchase-Airbyte-screen .airbyte-s-btns .edit-btn:hover,
.secound-time-purchase-Airbyte-screen .airbyte-s-btns .delete-btn:hover {
  text-decoration: underline;
}

.modal-delivery-Details label {
  color: #121416;
}

.modal-delivery-Details .modal-heading {
  font-size: 1.2rem;
  font-weight: 700;
}

.modal-delivery-Details .update-cancle-btn {
  display: flex;
  justify-content: flex-end;
}

.modal-delivery-Details .modal-update-btn {
  background-color: #37afe3;
  padding: 7px 40px;
  color: white;
}

.modal-delivery-Details .modal-cancle-btn {
  background-color: #2e353b;
  padding: 7px 40px;
  color: white;
}

@media (max-width: 991px) {
  .AirByteServer .databaseName,
  .AirByteServer .password,
  .AirByteServer .userName,
  .AirByteServer .url-ipaddress,
  .AirByteServer .port {
    width: 50%;
  }
}

@media (max-width: 575px) {
  .AirByteServer .databaseName,
  .AirByteServer .password,
  .AirByteServer .userName,
  .AirByteServer .url-ipaddress,
  .AirByteServer .port {
    width: 100%;
  }

  .payment-success-msg .payment-success-alert {
    flex-direction: column;
  }
  .payment-success-msg .payment-success-alert .alert-heading h6,
  .payment-success-msg .payment-success-alert .alert-heading h5 {
    text-align: center;
  }

  .payment-success-msg .payment-success-alert .payment-success-icon {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}

@media (max-width: 360px) {
  .modeOfDelivery-container .select-modeofDelivery {
    flex-direction: column !important;
  }
}
