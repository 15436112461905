.privacyPolicy {
  background-color: #f4f8fb;
}
.privacyPolicy .container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0px;
}
.privacyPolicy .privacyPolicyContainer {
  background-color: #fff;
  padding: 30px;
  border-radius: 5px;
}
.privacyPolicy .privacyPolicyContainer p {
  margin: 0px;
}
.privacyPolicy .privacyPolicyContainer .privacyPolicyHead p {
  font-size: 18px;
}
.privacyPolicy .privacyPolicyContainer .listOfPolicy .heading {
  color: #108abf;
  font-weight: 550;
  font-size: 16px;
  margin-bottom: 6px;
}
.privacyPolicy .privacyPolicyContainer .listOfPolicy .policies {
  margin-top: 15px;
  margin-bottom: 3px;
}
.privacyPolicy .privacyPolicyContainer .listOfPolicy .policies .list {
  margin: 15px 0px;
}
.privacyPolicy .privacyPolicyContainer .listOfPolicy .policies span {
  color: #505050;
  font-weight: 600;
}
.privacyPolicy .privacyPolicyContainer .listOfPolicy .policies .paragraphHead {
  margin-top: 3px;
  margin-bottom: 3px;
}
.privacyPolicy .privacyPolicyContainer .listOfPolicy .policies ul {
  margin-bottom: 10px;
  list-style-type: none;
}
.privacyPolicy .privacyPolicyContainer .listOfPolicy .policies .mailTo {
  text-decoration: underline;
}
.privacyPolicy .privacyPolicyContainer .listOfPolicy .policies .mailTo:hover {
  text-decoration: none;
}
