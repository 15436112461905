* .my-orders-container {
  color: #303030;
}
* .my-orders-container .btn:focus,
.my-orders-container .form-check-input:focus {
  box-shadow: none;
}
.my-orders-container .form-check-input:checked {
  background-color: #0f9e5c;
  border-color: none;
}
.my-orders-container {
  background-color: #f4f8fb;
  padding-bottom: 5rem;
  padding-top: 2.5rem;
}

.my-orders-container .subcription-orders .invoice-download-btn {
  color: #37afe3;
  text-align: center;
  text-decoration: none;
  padding: 0;
}

.my-orders-container .account-heading .Search-filter {
  display: flex;
  align-items: center;
}

.my-orders-container .modal {
  padding: 0;
}

.my-orders-container .modal .filter-btns {
  display: flex;
  justify-content: center;
}
.my-orders-container .modal .filter-btns .btn {
  color: white;
  padding: 4px 40px;
  border-radius: 8px;
}

.my-orders-container .Search-filter .search-orders .form-control {
  width: 400px;
  position: relative;
  border-start-end-radius: 0px;
  border-end-end-radius: 0px;
}


.my-orders-container .order-dates {
  display: flex;
  flex-wrap: wrap;
}
.my-orders-container .Search-filter .search-orders {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

.my-orders-container .Search-filter .search-orders .btn {
  color: #303030;
  background-color: #ffffff;
  border: 1px solid #ced4da;
}
.my-orders-container .Search-filter .search-orders .filter-btn {
  margin-left: 10px !important;
}
.EmptyOrers-Container .card {
  padding: 40px 0px;
}

.EmptyOrers-Container .card .card-text {
  font-size: 20px;
  color: #999999;
}

.EmptyOrers-Container .card .emptyOrders-shop-btn {
  font-size: 20px;
  padding: 7px 20px;
  font-weight: 300;
}

.order-filter-modal-header {
  padding: 0px !important;
}
@media (max-width: 1199px) {
  .my-orders-container
    .subscription-orders-container
    .order-delivery-expiry-details {
    display: flex;
    flex-direction: column;
  }
  .my-orders-container .subscription-orders-container .order-renew {
    text-align: end;
  }
}
@media (max-width: 850px) {
  .my-orders-container .search-orders .form-control {
    max-width: 250px;
  }
}
@media (max-width: 625px) {
  .my-orders-container .search-orders .form-control {
    max-width: 250px;
  }
}
@media (max-width: 575px) {
  .My-Account-container .not-symbol {
    margin-left: 0px;
  }
}
@media (max-width: 574px) {
  .my-orders-container .account-heading {
    display: flex;
    justify-content: space-evenly;
  }
  .my-orders-container .search-orders .form-control {
    max-width: 200px;
  }
  .my-orders-container .Search-filter {
    margin-top: 10px;
  }
}
@media (max-width:396px){
  .filter-btn{
    margin-top: 10px;
  }
}
