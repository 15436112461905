.footer-top {
  min-height: 250px;
  border-style: dashed;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: #cacaca;
  border-bottom-color: #cacaca;
  background: #24a7e0;
  background-image: url("../../pages/enrichImages/subs-env-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  align-items: center;
  margin: 0;
}
.footer-top-right {
  width: 50%;
}
.footer-top-right input {
  font-size: 20px;
  border: none;
}
.footer .phone-icon {
  margin-top: 4px;
}
.footer .contact {
  margin-left: 70px;
}
.footer .contact h5 {
  font-weight: 400;
}
.footer-top-right .btn {
  border: none;
  font-size: 20px;
  background-color: #2e353b;
  padding: 10px 40px;
}
.footer-top-right .invalid-feedback {
  font-weight: 500;
  box-shadow: none;
}
.btn-dark:active:focus {
  box-shadow: none;
}
.footer-top-right .is-invalid {
  border: 2px solid red;
}
.footer-top-right .btn:hover {
  background-color: #000;
}
.footer-btm {
  background-color: #324160;
}

.footer-btm .btm-content {
  position: relative;
}
.footer .legal-info,
.footer .address {
  margin-top: 53px;
}

.logo,
.legal-info,
.address {
  margin-top: 2rem;
}

.legal-info a,
.address a {
  text-decoration: none;
}

.legal-info a:hover,
.address a:hover {
  text-decoration: underline;
}

.footer .address {
  text-align: start;
}

.phone-icon {
  background-color: #ffffff;
  color: rgb(50, 63, 95);
  border-radius: 10px;
  font-size: 30px;
  width: 50px;
  height: 50px;
  float: left;
  text-align: center;
}

.up-arrow {
  position: absolute;
  right: 0;
  bottom: 20px;
}
.footer-top-right .input-group .form-control:focus-visible {
  box-shadow: none;
}
.up-arrow a {
  background-color: #1f2123;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 65px;
}

.arrow {
  color: white;
  font-size: 20px;
}

.up-arrow a:hover {
  background-color: #24a7e0;
  color: white;
}

.footer-rights {
  background-color: #1f2123;
  color: white;
}

.footer-rights .container {
  margin-bottom: 0;
}
.closebutton {
  border: none;
}

.closebutton .red-btn {
  background-color: #d21e1e;
  width: 60px;
  height: 60px;
  text-align: center;
  border-radius: 50px;
  font-size: 40px;
  font-weight: 700;
  color: white;
}
.footer .footer-top-content {
  display: flex;
  justify-content: space-between;
}
.success,
.unsuccess {
  width: 100px;
}

@media (max-width: 991.98px) {
  .footer-top-left,
  .footer-top-right {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer .footer-top-content {
    flex-direction: column;
  }
  .footer-logo img {
    width: 200px;
  }

  .contact h5 {
    font-size: 15px;
  }
  .phone-icon {
    font-size: 25px;
    width: 45px;
    height: 45px;
  }
}

@media (max-width: 767.98px) {
  .footer .footer-top-right {
    width: 100%;
  }
  .footer .footer-logo,
  .footer .enrich-information {
    text-align: center;
    word-spacing: 1px;
  }
  .footer .contact-info {
    display: flex;
    justify-content: center;
  }
  .footer .contact {
    margin-left: 25px;
  }
  .legal-info {
    text-align: center;
  }
  .footer .address {
    text-align: center;
  }

  .phone-icon {
    float: left;
  }

  .footer-enrich-rights {
    text-align: center;
  }
}
@media (max-width: 575.98px) {
  .footer-top-right {
    width: 100%;
  }
  .footer-top-right .input-group .form-control {
    width: 100%;
  }
  .footer-top-right .btn {
    padding: 7px 15px;
  }

  .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
  .input-group:not(.has-validation)
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .footer .input-group-append {
    padding: 10px 0px;
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 413px) {
  .footer-top-content .footer-top-left {
    margin-top: 10px;
  }
}
@media (max-width: 300px) {
  .footer-enrich-rights {
    font-size: 15px;
  }
}
