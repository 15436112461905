.forgotPasswordParent {
  background-image: url("../enrichImages/register-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.forgotPasswordParent .setPasswordContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 80px;
}
.forgotPasswordParent .setPasswordContainer .setPasswordFrom {
  max-width: 700px;
  background-color: #fff;
  padding: 25px 80px;
  border-radius: 5px;
}
.forgotPasswordParent
  .setPasswordContainer
  .setPasswordFrom
  .forgotPasswordHead {
  font-size: 40px;
  margin: 0px;
}
.forgotPasswordParent
  .setPasswordContainer
  .setPasswordFrom
  .forgotPasswordHead
  .forgotPasswordPara {
  font-weight: 600;
}
.forgotPasswordParent
  .setPasswordContainer
  .setPasswordFrom
  .buttonAndLoginLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.forgotPasswordParent
  .setPasswordContainer
  .setPasswordFrom
  .buttonAndLoginLink
  .btn-info {
  background-color: #37afe3;
  padding: 5px 20px;
  padding: 10px 30px;
  color: #fff;
  font-size: 20px;
  border-radius: 5px;
  border: none;
}
.forgotPasswordParent
  .setPasswordContainer
  .setPasswordFrom
  .buttonAndLoginLink
  .btn-info:hover {
  background-color: #7ec8ec;
}
.forgotPasswordParent .setPasswordFrom .buttonAndLoginLink .loginHerePara {
  font-size: 18px;
  color: #7ec8ec;
}

.forgotPasswordParent
  .setPasswordFrom
  .buttonAndLoginLink
  .loginHerePara
  :hover {
  text-decoration: underline;
}
.form-control.is-invalid{
  border-color: #dc3545 !important;
}
.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {  
  box-shadow:none;
}

.forgotPasswordParent .setPasswordFrom .form-label {
  font-size: 18px;
}
.forgotPasswordParent .setPasswordFrom .set-email {
  display: flex;
  flex-direction: column;
}
.forgotPasswordParent .setPasswordFrom .set-email input {
  font-size: 15px;
}
.forgotPasswordParent .setPasswordFrom .form-control-lg {
  border: 1px solid #dadada;
}
.forgotPasswordParent .setPasswordFrom .form-control-lg::placeholder {
  color: #bababa;
}

@media (max-width: 767px) {
  .forgotPasswordParent .setPasswordContainer {
    padding: 30px;
  }
}
@media (max-width: 657px) {
  .forgotPasswordParent
    .setPasswordContainer
    .setPasswordFrom
    .forgotPasswordHead {
    font-size: 30px;
  }
  .forgotPasswordParent .setPasswordContainer .setPasswordFrom {
    padding: 20px 30px;
  }
}
@media (max-width: 575.98px) {  
  .forgotPasswordParent .setPasswordContainer {
    padding: 20px 10px;
  }
}
@media (max-width: 480px) {
  .forgotPasswordParent
    .setPasswordContainer
    .setPasswordFrom
    .buttonAndLoginLink
    .btn-info {
    padding: 10px 25px;
  }
  .forgotPasswordParent .setPasswordContainer .setPasswordFrom {
    padding: 20px;
  }
  .forgotPasswordParent .setPasswordContainer {
    padding: 20px 0px;
  }
}
