.wishlist-items {
  display: flex;
  flex-direction: column;
}

.wishlist-products {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
}
.wishlist-add-to-cart,
.wishlist-Buy {
  background-color: #38afe4;
  padding: 6.5px 15px;
  font-size: 1rem;
  color: #fff;
}

.wishlist-add-to-cart {
  background: #2e343b;
  color: white;
}

.wishlist-Buy:hover {
  background-color: #178cbf;
}
.wishlist-container .wishlist-add-to-cart:hover {
  background-color: #505050;
}
.wishlist-add-to-cart:hover {
  color: white;
}
.wishlist-container .delete-action {
  padding-left: 0px;
}
.wishlist-container .product-action {
  padding: 0px;
  text-decoration: none;
  color: #38afe4;
  font-weight: 500;
}
.wishlist-container .product-action:hover {
  text-decoration: underline;
}
.wishlist-product-img {
  max-width: 170px;
}
.wishlist-container .product-action-span {
  padding: 0px 10px;
}
.wishlist-item-left {
  display: flex;
  align-items: center;
}
.empty-wishlistcart-img img {
  max-width: 100%;
}
.wishlist-item-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.wishlist-product-totalprice {
  width: 130px;
  font-weight: 550;
  text-align: end;
}
.wishlist-product-price{
  width: 130px;
  text-align: end;
  font-weight: 550;

}
.wishlist-product-quentity {
  width: 130px;
  text-align: end;
  font-weight: 550;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.wishlist-Quentity {
  width: 70%;
  text-align: center;
  border: 1px solid #e5e5e5;
  padding: 10px 15px;
  border-radius: 10px;
}
.quentity-count {
  float: left;
  text-align: center;
  padding: 2px;
}
.quentity-increment-decrement {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.wishlist-item-left .wishlist-product-and-links span {
  color: #38afe4;
  cursor: pointer;
}
.wishlist-container .wishlist-Buy:hover {
  color: #fff;
}

.wishlist-selected-product .cart-selected-Order {
  padding: 10px;
  font-size: 1.25rem;
  flex-wrap: wrap;
}
.wishlist-container .mycart-delete {
  margin: 0px;
}

.wishlist-product-name,
.wishlist-product-price h6,
.wishlist-product-totalprice {
  font-size: 1.25rem;
  word-break: break-word;
}

.empty-wishlistcart-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.empty-wishlistcart {
  background-color: #f4f8fb;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-wishlistcart-link {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  text-align: left;
  background-color: #dc3545;
}

.empty-wishlistcart-link:hover {
  background-color: #e65f5f;
  color: #fff;
}

.empty-wishlistcart-text {
  font-size: 30px;
}
.modal-for-delete .react-cross-icon {
  font-size: 100px;
  font-weight: 100;
  color: #f27474;
}

.modal-for-delete .make-me-invisible {
  position: absolute;
  right: -6px;
  top: -12px;
  background: #d21e1e;
  color: white !important;
  border-radius: 25px;
  width: 30px;
  height: 30px;
  opacity: 10 !important;
}
.modal-for-delete .modal-body {
  padding: 0px;
  padding-top: 30px;
}
.modal-for-delete .modal-body p {
  font-weight: 500;
}
.modal-for-delete .modal-footer {
  padding: 0px;
  padding-bottom: 30px;
}
.modal-for-delete .make-me-invisible span {
  position: absolute;
  font-size: 40px;
  top: -8px;
  right: 12px;
}
.modal-for-delete .buttons-for-delete-confirm {
  display: flex;
  justify-content: center;
}
.modal-for-delete .buttons-for-delete-confirm .btn-danger {
  background-color: #f46a6a;
  border: none;
  padding: 6px 25px;
}
.modal-for-delete .buttons-for-delete-confirm .btn-success {
  background-color: #36ab74;
  border: none;
  padding: 6px 20px;
}

.modal-for-delete .modal-header,
.modal-for-delete .modal-footer {
  border: none;
}
@media (max-width: 575px) {
  .empty-wishlistcart-content {
    flex-direction: column;
  }
  .empty-wishlistcart-img {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
  }
}

@media (max-width: 991px) {
  .wishlist-product-quentity,
  .wishlist-product-price,
  .wishlist-product-totalprice {
    width: 100px;
  }
  .wishlist-product-name,
  .wishlist-product-price h6,
  .wishlist-product-totalprice {
    font-size: 1rem;
  }

  .wishlist-product-img {
    max-width: 170px;
  }
}

@media (max-width: 767px) {
  .wishlist-container .wishlist-products {
    flex-wrap: wrap;
  }
  .wishlist-item-right {
    margin-top: 20px;
    margin-left: 15px;
  }
  .wishlist-product-totalprice {
    text-align: start;
    padding-left: 33px;
  }
}
@media (max-width: 601px) {
  .select-products-details {
    display: flex;
    padding: 5px;
    justify-content: center;
  }
  .delete-cart-buy {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .wishlist-item-left,
  .wishlist-item-right {
    flex-wrap: wrap;
    width: 100%;
  }

  .wishlist-item-right {
    justify-content: flex-start;
  }

  .wishlist-item-left .wishlist-product-and-links {
    margin-top: 20px;
    padding-left: 25px;
  }
}

@media (max-width: 338px) {
  .wishlist-item-left .wishlist-product-and-links {
    padding: 0;
  }
  .wishlist-item-right {
    margin-left: 0;
  }
}
