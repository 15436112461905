.spinner-border {
  width: 4rem;
  height: 4rem;
}
.product-detail-section {
  display: flex;
  justify-content: space-between;
}

.details-section {
  max-width: 1200px;
  height: auto;
}
.img-section img {
  width: 90%;
  height: auto;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.download-sample-data-btn{
  border-width: 2px !important;  
  border-color: #ffb847  !important;
  color: #ffb847 !important;
  background-color: #fff !important;
}
.wishlist-btn .download-icon{
  padding-right: 5px;
  padding-bottom: 6px;
}
.condition-checkbox .text-reset{
  text-decoration: none;
  color: #37afe3 !important;
}
.condition-checkbox .text-reset:hover{
  text-decoration: underline;
}

.pd-section-body {
  padding: 40px;
  border-radius: 7px;
}
.product-detail-container .card {
  border-radius: 7px;
}
.feature-list {
  list-style: none;
  margin-left: 5px;
}
.feature-list .icon {
  margin-right: 15px;
}

.description-text {
  color: #505050;
  font-size: 1.3rem;
}

.feature {
  font-size: 1.2rem;
  color: #505050;
}

.feature-heading {
  color: #505050;
  font-weight: 700;
}

.renewal-checkbox input,
.condition-checkbox input {
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 1.25rem;
  margin-top: 1px;
}

.renewal-checkbox .form-check-input:checked,
.condition-checkbox .form-check-input:checked {
  background-color: #0f9e5c;
  border: none;
}
.renewal-checkbox {
  font-size: 1rem;
}

.product-btn-group .buy-btn,
.product-btn-group .add-btn,
.product-btn-group .wishlist-btn {
  margin-right: 10px;
}

.product-btn-group .wishlist-btn {
  color: #fff;
  background-color: #ff9f00;
}

.product-btn-group .add-btn {
  color: white;
}

.product-btn-group .buy-btn {
  background-color: #37afe3;
  color: white;
}
.product-detail-container .shop-product-heading .text-end p {
  font-size: 20px;
}
.product-detail-container .shop-product-heading svg {
  margin: 0px 10px;
}

.shop-product-heading .home:hover {
  color: #178cbf;
  text-decoration: none;
  text-decoration: underline;
}

.product-btn-group .add-btn:hover {
  color: #fff;
  background-color: #404040;
}
.product-btn-group .buy-btn:hover {
  color: #fff;
  background-color: #178cbf;
}
.product-btn-group .wishlist-btn:hover {
  color: #fff;
  background-color: #ffb847;
}
.product-btn-group .wishlist-btn svg {
  font-size: 30px;
}
.product-detail-container .name-heading {
  font-size: 33px;
}
.popup-body {
  width: 100%;
  display: flex;
}
.popup-img-section {
  margin-right: 20px;
}
.popup-img-section img {
  width: 90px;
}

.popup-success-img {
  width: 20px;
}

.popup-success-message {
  font-size: 13px;
}

.popup-btn .shoping-btn {
  padding: 5px 10px;
  font-size: 13px;
  background-color: #2e353b;
  color: white;
  border-radius: 7px;
}
.popup-btn .cart-btn {
  padding: 5px 10px;
  font-size: 13px;
  background-color: #37afe3;
  color: white;
  border-radius: 7px;
}

.shoping-btn {
  margin-right: 0.5rem;
}

@media (max-width: 950px) {
  .product-detail-container .wishlist-btn {
    margin-top: 10px;
  }
}

@media (max-width: 852px) {
  .product-btn-group .buy-btn,
  .product-btn-group .add-btn,
  .product-btn-group .wishlist-btn {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .img-section {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  .details-section {
    width: 100%;
  }

  .img-section img {
    width: 100%;
  }

  .pd-section-body {
    padding: 28px;
  }
  .product-detail-container .wishlist-btn {
    margin-top: 0px;
  }
}

@media (max-width: 575px) {
  .product-detail-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .details-section {
    margin-top: 30px;
  }
  .product-detail-container .product-btn-group .btn-lg {
    font-size: 16px;
  }
  .product-detail-section .details-section .name-heading,
  .product-detail-section .details-section .price-heading {
    font-size: 30px;
  }
  .product-detail-section .details-section .description-text {
    font-size: 18px;
  }
  .product-btn-group .wishlist-btn svg {
    font-size: 25px;
  }
}

@media (max-width: 542px) {
  .product-btn-group .buy-btn,
  .product-btn-group .add-btn,
  .product-btn-group .wishlist-btn {
    font-size: 20px;
    margin-bottom: 10px;
  }
}
@media (max-width: 479px) {
  .product-detail-container .product-btn-group .btn-lg {
    font-size: 14px;
  }
}
@media (max-width: 430px) {
  .popup-btn .cart-btn {
    margin-top: 10px;
  }
}

@media (max-width: 768px) and (min-width: 576px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 800px;
  }
}
