html {
  scroll-behavior: smooth;
}
body {
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
}

#goog-gt-tt {
  display: none !important;
}

.btn:focus,
.form-check-input:focus,
.navbar-toggler:focus,
.form-control:focus,
.form-select:focus,
.btn-close:focus {
  box-shadow: none;
}
.enrichShopingCart,
.empty-wishlistcart,
.contactUsPage,
.hotproduct,
.forgotPasswordParent,
.footer,
.shopcontainer,
.shopproducts,
.registerLoginContainer,
.modeOfDelivery-heading,
.modeOfDelivery-container,
.My-Account-container,
.EmptyMyAccount-container,
.my-orders-container,
.EmptyOrers-Containers,
.payment-information,
.Mode-of-delivery-loader,
.product-detail-section,
.privacyPolicy,
.product-detail-container,
.setPasswordParent,
.termsService,
.wishlist-container {
  background: #f4f8fb;
}
.card-body,
.wishlist-products,
.cart-items {
  background-color: #fff;
}
