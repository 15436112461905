.cart-product-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-selected-Order {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #edf0f1;
  border: 2px dashed #e8eaee;
  padding: 10px;
  font-size: 1.25rem;
  border-radius: 5px;
}
.mycart-delete,
.mycart-wishlist {
  font-size: 40px;
  margin-left: 10px;
  padding: 6px 6px;
  border: 1px solid #505050;
  border-radius: 7px;
  background-color: white;
  color: grey;
}

.mycart-delete {
  color: #eb595a;
  border: 1px solid #eb595a;
}

.deleted-icon {
  font-size: 50px;
  margin-left: 10px;
  padding: 10px 10px;
  color: #eb595a;
  border: 1px solid #eb595a;
  border-radius: 7px;
}
.wishlist-container .product-action:focus {
  box-shadow: none;
}
.wishlist-container .product-action:focus-visible {
  border: none;
}
.shop-container {
  padding: 30px 0px;
}
.cart-items {
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
}

.cart-product-img {
  max-width: 170px;
}

.cart-items-left {
  display: flex;
  align-items: center;
}
.cart-product-img,
.cart-product-select {
  margin-right: 20px;
}
.cart-items-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.enrichShopingCart .total-amount {
  text-align: right;
}
.cart-selected-price,
.cart-product-quentity,
.cart-total-selected-price,
.mycart-product-delete {
  width: 85px;
  text-align: end;
}
.cart-product-quentity {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.cart-Quentity {
  width: 70%;
  text-align: center;
  border: 1px solid #e5e5e5;
  padding: 10px 15px;
  border-radius: 10px;
}

.cart-product-name,
.cart-selected-price,
.cart-total-selected-price {
  font-size: 1.25rem;
  font-weight: 600;
  word-break: break-word;
}

.mycart-product-mid {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.proceed-btn {
  background-color: #36b0e4;
  color: white;
}

.proceed-btn:hover {
  background-color: rgb(23, 140, 191);
  color: white;
}

.cart-note {
  background-color: #f1f5f9;
  padding: 30px 20px;
  border-radius: 10px;
}

.mycart-note-text {
  font-size: 1.25rem;
  border: none;
  border-radius: 10px;
}
.empty-wishlistcart-content {
  display: flex;
  justify-content: space-evenly;
}

.mycart-product-mid .span-price {
  font-weight: 400;
}
.cart-note h3 {
  font-weight: 600;
}
.cart-note textarea {
  padding: 10px 30px;
}
.empty-wishlistcart-link {
  margin-top: 10px;
}

@media (max-width: 992px) {
  .container,
  .container-lg,
  .container-sm {
    max-width: 94%;
  }
}

@media (max-width: 991px) {
  .cart-selected-price,
  .cart-product-quentity,
  .cart-total-selected-price {
    width: 95px;
  }
  .cart-product-name,
  .cart-selected-price h6,
  .cart-total-selected-price {
    font-size: 1rem;
  }
  .cart-product-img {
    max-width: 200px;
  }
}

@media (max-width: 744px) {
  .cart-products {
    flex-wrap: wrap;
  }
  .cart-items-right {
    margin-top: 20px;
    margin-left: 15px;
  }
}

@media (max-width: 601px) {
  .cart-selected-product .cart-selected-Order {
    flex-wrap: wrap;
    text-align: center;
    padding: 5px;
  }
  .cart-selected-Order .delete-wishlist,
  .cart-selected-Order .select-products-details {
    width: 100%;
    padding: 5px;
  }
}

@media (max-width: 575px) {
  .cart-products {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 570px) {
  .cart-items {
    padding: 20px 0px;
  }
  .cart-product-heading {
    flex-wrap: wrap;
  }

  .cart-heading {
    width: 100%;
  }
  .mycart-product-mid {
    padding: 0px 20px;
  }
  .cart-items-left {
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: 30px;
  }

  .cart-product-img {
    width: 62%;
  }
  .cart-product-name {
    width: 100%;
    margin-top: 20px;
    text-align: start;
    font-size: 1rem;
    padding: 0px 40px;
    padding-right: 45px;
    word-wrap: break-word;
  }

  .cart-items-right {
    width: 100%;
    margin-top: 0px;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .cart-items-right .mycart-product-delete {
    width: 30%;
    text-align: start;
    padding: 10px 20px;
  }
  @media (max-width: 470px) {
    .cart-selected-price,
    .cart-product-quentity,
    .cart-total-selected-price,
    .mycart-product-delete {
      width: 100px;
    }

    .enrichShopingCart .mycart-product-mid {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }
    .enrichShopingCart .mycart-product-mid h3 {
      margin-top: 10px;
    }
    .enrichShopingCart .proceed-btn {
      font-size: 16px;
      padding: 8px 10px;
      margin: 0px 20px;
      display: flex;
      justify-content: center;
    }
  }
}
