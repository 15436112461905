.payment-container {
  background-color: #f4f8fb;
  padding-bottom: 5rem;
  padding-top: 4rem;
}
.payment-container .payment-information {
  padding: 40px 30px;
  border: 1px solid #c9c6c6;
  border-radius: 10px;
  background-color: #fafafa;
}

.payment-container .payment-information label {
  color: #3d3e3f;
  font-size: 1.3rem;
}

.payment-information .form-control:focus,
.payment-information .form-select:focus {
  box-shadow: none;
  border: 1px solid #303030;
}
.payment-container .payment-information .countrySelect .css-13cymwt-control {
  height: 60px !important;
  padding: 0.45rem 0.75rem;
  font-size: 1.3rem;
}

.payment-container .payment-information .countrySelect .css-t3ipsp-control {
  height: 60px !important;
  border-color: grey !important;
  border: 1px solid #ced4da !important;
  box-shadow: none !important;
}
.css-1fdsijx-ValueContainer {
  font-size: 1.3rem;
}
.cart-hediing-right p svg{
  vertical-align: middle !important;
  color: #827d7d;
  margin: 0px 7px !important;
  font-size: 1.3rem !important;
  margin-bottom: 4px !important;
}
.address-information-span{
  font-size: 20px;
  margin-left: 2px;
}
.cart-payment-btn .back-to-cart {
  border: 1px solid #505050;
  padding: 10px 25px;
  font-size: 1.3rem;
  font-weight: 700;
}

.cart-payment-btn .roundArrow {
  font-size: 1.5rem;
  font-weight: 700;
}

.cart-payment-btn .back-to-cart:hover {
  background-color: black;
  color: white;
}
.cart-payment-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.returncart-arrow {
  text-align: center;
}

.cart-payment-btn .returncart-arrow .roundArrow {
  margin-right: 1rem;
}

.continue-payment {
  background-color: #37afe3;
  padding: 10px 25px;
  color: white;
  font-size: 1.3rem;
}

.continue-payment:focus {
  box-shadow: none;
}

.continue-payment:hover {
  background-color: #3589ad;
  color: white;
}

.Mode-of-delivery-loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.payment_failed {
  width: 99.99vw;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment_failed .process-failed-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.payment_failed .process-failed-content .payment-failed-header {
  font-size: 2rem;
  font-weight: 700;
}

.payment_failed
  .process-failed-content
  .payment-failed-header
  .failed-mdg-icon {
  font-size: 3rem;
  margin-right: 0.5rem;
}

.payment_failed .process-failed-content .payment-failed-text {
  font-size: 24px;
  color: #303030;
  font-weight: 500;
}
.payment_failed .process-failed-content .payment_failed-btn .try-again-btn {
  background-color: rgb(241, 241, 241);
  padding: 10px 20px;
  color: #303030;
  font-weight: 700;
  border-radius: 8px;
}

.react-tel-input .form-control {
  width: 100%;
  height: autoc;
}
@media (max-width: 590px) {
  .cart-payment-btn {
    flex-direction: column;
  }

  .cart-payment-btn .back-to-cart {
    margin-bottom: 1rem;
  }

  .cart-payment-btn .continue-payment {
    padding: 10px 15px;
  }
}

@media (max-width: 360px) {
  .cart-payment-btn .back-to-cart,
  .cart-payment-btn .continue-payment {
    font-size: 1rem;
    padding: 7px 18px;
  }
}
