.hotproduct {
  padding: 1rem 0rem;
  min-height: 60vh;
}

.slick-dots li button:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 6px;
  background-color: #24a7e0;
  margin: 0px 5px;
}
.hotproduct .slick-dots {
  margin-bottom: 20px;
}
.hotproduct .product-heading {
  margin-bottom: 1rem;
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
}
.hot-product-card-body{
  height: 155px !important;
}
.hotproduct-container .hot-product-card-body{
  height: 150px !important;
}
.product-left {
  font-weight: 1000;
  font-size: 35px;
  display: flex;
  justify-content: left;
  align-items: center;
}
.product-left span {
  color: #24a7e0;
  margin-right: 5px;
}
.product-right {
  display: flex;
  align-items: center;
  justify-content: end;
}
.shop-link {
  position: relative;
  text-decoration: none;
  color: #505050;
  font-size: 20px;
}

.shop-link::before {
  content: "";
  position: absolute;
  top: -9.5px;
  left: 22px;
  width: 50px;
  height: 50px;
  border-radius: 60px;
  background-color: #e8f6fd;
  z-index: -1;
}
.product-right svg {
  margin-left: 7px;
}

.products-card {
  margin-bottom: 2rem;
  height: 100%;
}

.products-card .card-text {
  font-weight: 800;
}
.product-img {
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.product-img:hover .product-icons {
  display: block;
}
.product-icons {
  display: none;
  position: absolute;
  right: 25px;
  bottom: 20px;
  width: 40px;
}
.empty-hot-product img{
  width: 100%;
}
.product-icons a {
  display: block;
  width: 50px;
  height: 50px;
  background-color: white;
  line-height: 20px;
  margin-bottom: 8px;
  color: #505050;
  padding: 10px 5px;
  text-align: center;
}
.product-icons a:hover {
  color: #303030;
}

.bag,
.heart,
.search {
  font-size: 30px;
  color: #959ea5;
}
.slick-track{
  display: flex
}
.slick-slide{
  width: 324px !important;
}

.slick-slide .product-container {
  padding: 10px 10px;
  width: 320px !important;
}

.active {
  color: #178cbf;
}

.product-content {
  border: 2px solid red;
  display: flex;
}

.product-detail-show-link {
  text-decoration: none;
  color: #303030;
}

.product-detail-show-link:hover {
  color: #303030;
}

.products-card .card-body .card-title {
  text-decoration: none;
  font-size: 20px;
  color: #303030;
  display: block;
  min-height: 70px;
}

.products-card .card-body .card-title:hover {
  text-decoration: underline;
}


@media (max-width: 1399.98px) {
  .product-icons a {
    width: 40px;
    height: 40px;
    padding: 5px 3px;
  }

  .bag,
  .heart,
  .search {
    font-size: 25px;
  }
}

@media (max-width: 991.98px) {
  .shop-link {
    font-size: 18px;
  }
  .shop-link::before {
    top: 0px;
    left: 25px;
    width: 30px;
    height: 30px;
  }

  .product-left h1 {
    font-size: 30px;
  }
}
@media (min-width: 481px) and (max-width: 520px){
/* .hot-product-card-body{
  height: 230px !important;
} */
}
@media (max-width: 480.98px) {
  .products-card {
    border: none;
  }
}
@media (max-width: 420px) {
  .product-left {
    font-size: 30px;
  }
}
@media (max-width: 340px) {
  .product-heading {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
