.setPasswordParent {
  background-image: url("../enrichImages//register-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.setPasswordParent .setPaswordDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 45px;
}
.setPasswordParent .setPaswordDiv .setPasswordFrom {
  background-color: #fff;
  max-width: 650px;
  padding: 25px 80px;
  border-radius: 5px;
}
.setPasswordFrom .form-control.is-invalid{
  background-image: none;
}
.setPasswordParent .setPaswordDiv .setPasswordFrom .show-password {
  position: relative;
  float: right;
  top: -43px;
  left: -20px;
  font-size: 25px;
  color: #7d7f7e;
}
.setPasswordParent .setPasswordFrom input::placeholder {
  font-size: 15px;
}

.setPasswordParent .setPasswordFrom .btn-info {
  background-color: #45bef7;
  color: #fff;
}
.setPasswordParent .setPaswordDiv .setPasswordDivHeader .passwordPara {
  font-size: 35px;
}
@media (max-width: 628px) {
  .setPasswordParent .setPaswordDiv .setPasswordFrom {
    padding: 25px 40px;
  }
}
@media (max-width: 576px) {
  .setPasswordParent .setPaswordDiv {
    padding: 20px 10px;
  }
  .setPasswordParent .setPaswordDiv .setPasswordFrom {
    padding: 20px 20px;
  }
}
@media (max-width: 412px) {
  .setPasswordParent .setPaswordDiv .setPasswordDivHeader .passwordPara {
    font-size: 20px;
    font-weight: 550;
  }
  .setPasswordParent .setPaswordDiv {
    padding: 20px 0px;
  }
}
