.contactUsPage {
  background-color: #f4f8fb;
  padding: 40px 0px;
}
.contactUsPage .contact-us-page-heading {
  font-size: 40px;
}
.contactUsPage .card {
  padding: 25px;
  background-color: #fff;
  border-radius: 5px;
}

.contactUsPage .addressDiv {
  border: 1px solid #dadada;
  padding: 20px;
  border-radius: 8px;
}
.contactUsPage .addressDiv .address {
  margin: 0px;
  font-size: 22px;
}
.contactUsPage .addressDiv .contactInfo {
  margin-top: 20px;
}
.contactUsPage .addressDiv .contactInfo a {
  font-size: 20px;
  text-decoration: underline;
  color: #38afe4;
}
.contactUsPage .addressDiv .contactInfo a:hover {
  color: #38afe4;
  text-decoration: none;
}
.contactUsPage .nameDiv {
  margin-right: 30px;
}
.contactUsPage .nameAndEmail input::placeholder {
  font-size: 15px;
}
.contactUsPage .nameAndEmail {
  display: flex;
}
.contactUsPage .sendButton .btn-info {
  padding: 10px 50px;
  border-radius: 5px;
  background-color: #38afe4;
  color: #fff;
  font-weight: 500;
}
.react-right-icon-container {
  text-align: center;
  padding-top: 25px;
  padding-bottom: 10px;
}
.popup-close-button-div {
  padding: 0px;
  position: absolute;
  right: -18px;
  top: -18px;
}
.popup-close-button {
  position: relative;
  left: 0px;
  top: 0px;
  border: none;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  background-color: #d21e1f;
  color: #fff;
  font-size: 25px;
}
.text-content {
  color: #000;
}
.react-right-icon,
.react-wrong-icon {
  width: 80px;
}
.react-right-icon-container .modal-mid-text {
  font-weight: 400;
}

@media (max-width: 710px) {
  .contactUsPage .nameAndEmail {
    flex-direction: column;
    justify-content: center;
  }
  .contactUsPage .emailDiv {
    margin: 0;
  }
  .contactUsPage .nameDiv {
    margin: 0;
  }
}
@media (max-width: 444px) {
  .contactUsPage .sendButton {
    justify-content: center;
  }
  .contactUsPage .addressDiv {
    text-align: center;
  }
}
