.enrich-header .enrich-header-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.impersonate-header{
  width: 100vw;
  display: flex;
  justify-content: center;
  background-color: #24a8e1;  
  padding: 5px;
  color: #fff;
  font-weight: 500;  
}
.impersonate-header .btn-danger{
  margin-left:15px;
  padding: 2px 15px;
}
.navbar-expand-md .container-fluid{
  justify-content: center;
}
.enrich-header .navbar-expand-md {
  flex-wrap: wrap;
}

.enrich-header .enrich-header-btm {
  width: 100%;
  background-color: #37afe3;
}
.enrich-header .container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}
.enrich-header .enrich-header-top .enrich-header-top-right .right-items {
  padding-left: 0px;
}

.right-items .nav-item {
  text-align: center;
  padding: 0px 10px;
}
.enrich-header-top {
  padding: 10px 0px;
}

.header-btm-content .nav-item .active {
  background-color: #178cbf;
  border-radius: 10px;
}
.enrich-header-btm-right {
  text-align: end;
}

.enrich-header-btm-right .btn-help-center {
  background-color: rgb(23, 140, 191);
  border: 1px solid #3589ad;
  display: flex;
  align-items: end;
  color: #fff;
}

.enrich-header-btm-right .btn-help-center:hover {
  background: #26799c;
  border-color: #26799c;
}

.header-btm-content .nav-item .nav-link {
  border-radius: 0;
  padding: 20px 10px 20px;
}

.right-items .nav-link {
  background: none;
  color: black;
  border: none;
}

.right-items .nav-link span {
  width: 30px;
  height: 30px;
  top: 15px;
  left: 45px;
  background-color: rgba(48, 48, 48, 0.8);
  border: none;
}

.right-items .wishlist-link span {
  width: 30px;
  height: 30px;
  top: 15px;
  left: 65px;
  background-color: rgba(48, 48, 48, 0.8);
  border: none;
}

.right-items .nav-link .dropdown-toggle {
  background: none;
  border: none;
}

.right-items .nav-link:hover {
  text-decoration: underline;
}
.header-btm .nav-item:hover {
  background-color: #24a8e1;
}
.drop-item {
  padding: 25px 25px;
  width: 220px;
  border-radius: 7px;
  left: -125px !important;
  margin-top: 9px !important;
}

.drop-item .nav-link {
  line-height: 15px;
}
.drop-item .btn-logout {
  background-color: #e65f5f !important;
  padding: 5px 20px;
  border: none;
  border-radius: 7px;
}

.drop-item .btn-logout:hover {
  background-color: #ee4545 !important;
}

.drop-item .nav-link:hover {
  color: rgb(23, 140, 191);
  text-decoration: none;
}
.dropdown-menu[data-bs-popper] {
  left: -60px;
}
.enrich-header-top .navbar {
  display: flex;
  justify-content: space-between;
}
.question {
  font-size: 20px;
  margin-right: 5px;
  margin-bottom: 3px;
}

.menu {
  font-size: 30px;
}

.navbar-wishlist,
.navbar-user,
.navbar-cart {
  font-size: 30px;
  margin-bottom: 5px;
}

.enrich-header-btm .header-btm-content {
  padding-left: 10px;
}
.enrich-header-btm .container {
  padding: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.enrich-header-btm .enrich-header-btm-right {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.enrich-header .navbar {
  padding: 0px;
}

.enrich-header-btm {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.enrich-header .enrich-header-top .right-items .account-link {
  padding: 0px;
}
.enrich-header .enrich-header-top .right-items .orders-link {
  padding-bottom: 0px;
  padding-left: 0px;
}

.emptyCart-dropdown {
  width: 300px;
  line-height: 2rem;
  padding: 20px 0px;
  left: -225px !important;
  margin-top: 9px !important;
}

.emptyCart-dropdown img {
  width: 70px;
}

.emptyCart-dropdown .btn {
  background-color: #37afe3;
  color: white;
  font-weight: 400;
  width: 70%;
  border-radius: 7px;
  text-decoration: none;
}
.emptyCart-dropdown .btn:hover {
  background-color: rgb(23, 140, 191);
}

.cart-dropdown {
  margin-top: 9px !important;
  border-radius: 7px;
  width: 300px;
  left: -225px !important;
  padding: 14px 0px;
}
.cart-dropdown-list {
  padding: 0px 15px;
}
.cart-dropdown-content {
  display: flex;
}
.cart-dropdown-img img {
  width: 60px;
  margin-right: 20px;
}
.cart-dropdown-text p {
  padding: 0;
  margin: 0;
  color: #303030;
  font-weight: 500;
  font-size: 14px;
}

.cart-dropdown-text .cart-dropdown-price {
  font-weight: 600;
}
.cart-dropdown-total span {
  font-weight: 400;
  font-size: 16px;
}
.cart-dropdown-total .total-price {
  font-weight: 700;
  padding-left: 5px;
}

.cart-dropdown-total {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0px 15px;
  margin-bottom: 8px;
}

.cart-dropdown-btn {
  padding: 8px 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.google-tanslate-nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-dropdown-btn .view-cart-btn,
.cart-dropdown-btn .drop-checkout-btn {
  text-decoration: none;
  color: white;
  font-weight: 400;
  border-radius: 7px;
  padding: 5px 15px;
  font-size: 14px;
}
.cart-dropdown-btn .view-cart-btn {
  margin-right: 10px;
  background-color: #37afe3;
}
.cart-dropdown-btn .view-cart-btn:hover {
  background-color: rgb(23, 140, 191);
}
@media (max-width: 767px) {
  .enrich-header .enrich-header-top .right-items {
    position: absolute;
    width: 100%;
    top: 50%;
    right: 0;
    background-color: white;
    text-align: center;
    z-index: 1;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
  }
  .enrich-header .enrich-header-btm .navbar-nav {
    flex-direction: row !important;
  }
  .enrich-header .enrich-header-top .right-items .account-drop-down {
    position: absolute;
    left: 0;
  }
}

@media (max-width: 516px) {
  .enrich-header-btm-right {
    position: relative;
    top: 0px;
  }
}
@media (max-width: 479px) {
  .enrich-header-btm-right .btn-primary {
    display: none;
  }
}
@media (max-width: 450px) {
  .enrich-header-btm .container {
    flex-direction: column;
  }
  .enrich-header-btm-right .btn-help-center {
    display: none;
  }
  .enrich-header-btm .enrich-header-btm-right {
    width: 100%;
    justify-content: flex-start;
    margin-left: 35px;
  }
}
@media (max-width: 375px) {
  .enrich-header-top {
    flex-direction: column;
    align-items: start;
  }
  .enrich-header .enrich-header-top .enrich-header-top-right {
    width: 100%;
    justify-content: flex-start;
  }
  .enrich-header .enrich-header-top .enrich-header-top-left {
    width: 100%;
    justify-content: flex-start;
  }
  .enrich-header .enrich-header-top .enrich-header-top-right .right-items {
    padding: 10px 0px;
    top: 52%;
    flex-wrap: wrap;
    margin: 10px;
  }
  .enrich-header .enrich-header-top .right-items .account-drop-down {
    width: 100px;
  }
}
@media (max-width: 289px) {
  .container,
  .container-lg,
  .container-sm {
    max-width: 100% !important;
  }
}
