* li {
  list-style-type: none;
}
.termsService {
  background-color: #f4f8fb;
}
.termsService .container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0px;
}
.termsService .termsServiceContainer {
  background-color: #fff;
  padding: 30px;
  border-radius: 5px;
}
.termsService .termsServiceContainer p {
  margin: 0px;
  font-size: 16px;
}
.termsService .termsServiceContainer .termsServiceHead p {
  font-size: 18px;
}
.termsService .termsServiceContainer .listOfTerms .heading {
  color: #108abf;
  font-weight: 550;
  font-size: 16px;
  margin-bottom: 3px;
}
.termsService .termsServiceContainer .listOfTerms .terms {
  margin-top: 15px;
  margin-bottom: 3px;
}
.termsService .termsServiceContainer .listOfTerms .termslist {
  margin: 10px 0px 0px 0px;
}
.termsService .termsServiceContainer .listOfTerms .terms span {
  color: #505050;
  font-weight: 600;
}
.termsService .termsServiceContainer .listOfTerms .terms .mailTo {
  text-decoration: underline;
}
.termsService .termsServiceContainer .listOfTerms .terms .mailTo:hover {
  text-decoration: none;
}
.termsService .termsServiceContainer .listOfTerms .term5 ul {
  list-style-type: none;
}
