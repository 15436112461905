.shopproducts {
  padding-bottom: 25px;
  min-height: 70vh;
}

.grid-align,
.full-grid-align {
  font-size: 40px;
  border: 1px solid;
  padding: 10px 10px;
  border-radius: 6px;
  margin: 0px 2px;
  color: #505050;
}

.remove-item {
  background-color: #0f9e5c;
  border-radius: 5px;
  color: #fff;
  font-weight: 900;
  height: 21px;
  margin-right: 5px;
  text-align: center;
  width: 20px;
}
.remove-item span {
  position: relative;
  top: -20.5px;
  left: 0px;
  color: #fff;
}

.sort-select,
.page-select {
  padding: 5px 10px;
  margin: 0px 2px;
  text-align: center;
  border-radius: 5px;
}

.order-details {
  border: 1px solid grey;
  text-align: center;
  border-radius: 7px;
}

.cart-wishlist {
  color: #505050 !important;
  float: right;
  margin-left: 120px;
}

.shop-cart,
.shop-wishlist {
  font-size: 30px;
  margin-left: 10px;
  padding: 3px 3px;
  border: 1px solid #505050;
  border-radius: 7px;
  background-color: white;
}

.shop-cart:hover,
.shop-wishlist:hover {
  color: rgb(55, 175, 227);
  border: 1px solid rgb(55, 175, 227);
}

.visible-content {
  background-color: #edf0f1;
  padding: 10px 15px;
  border-radius: 7px;
}

.select-products-details {
  float: left;
  display: flex;
  align-items: center;
}

.cart-wishlist a {
  text-decoration: none;
  color: #505050;
}

.shop-product-customize {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shop-product img {
  position: relative;
}

.sort-select {
  margin-right: 10px;
}

.shop-product-customize .left .form-select-md:focus {
  outline: #178cbf;
}

.shop-product-customize .right .active {
  background-color: #178cbf;
  color: white;
}

.product-select {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-select .form-check-input,
.select-title .form-check-input {
  font-size: 20px;
}

.product-select .form-check-input:checked,
.select-title .form-check-input:checked {
  background-color: #0f9e5c;
  border: none;
}

.full-card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 10px 10px;
}
.shop-product {
  margin-bottom: 24px;
}
.product-select .form-check-input:checked {
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.product-description {
  margin-top: 30px;
  color: #505050;
}

.check {
  margin-right: 20px;
  font-size: 25px;
}


.full-card-content .select-title {
  display: flex;
  flex-direction: row;
}
.shopcontainer .total-Order {
  display: flex;
  align-items: center;
}
.card-body .product-description {
  padding-left: 44px;
  margin: 0;
  font-size: 1.2rem;
}

.select-title {
  margin: 0px 20px;
}

.full-card img {
  width: 100%;
  height: auto;
}

.infinite-scroll {
  overflow-x: hidden !important;
}

.grid-product {
  max-width: 300px;
}

.grid-product-img {
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.grid-product-img:hover .grid-product-icons {
  display: block;
}

.grid-product-icons {
  display: none;
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 40px;
}
.shopcontainer .select-title .form-check-input {
  margin-top: 0.4em;
}
.heading-link {
  padding: 0;
  text-decoration: none;
  color: #505050;
}

.heading-link-prev {
  padding: 0;
  text-decoration: none;
  color: #178cbf;
  font-size: 20px;
}

.heading-link-prev:hover {
  text-decoration: underline;
  color: #178cbf;
  text-decoration-color: #178cbf;
}
.shift-heading svg {
  margin: 0px 7px;
  color: #827d7d;
}
.shift-heading p {
  font-size: 20px;
  text-align: end;
}

.grid-product-icons a {
  display: block;
  width: 35px;
  height: 35px;
  background-color: white;
  line-height: 20px;
  margin-bottom: 8px;
  color: #505050;
  padding: 4px 5px;
  text-align: center;
}

.grid-product-icons a:hover {
  color: #303030;
}

.grid-icon {
  font-size: 1.5rem !important;
}

.active {
  color: #5cb85c;
}

.success-img {
  width: 50px;
  background: none;
}

.product-heading {
  text-align: start;
  font-weight: 500;
}
.grid-card-title-price {
  font-size: 20px;

}
.grid-card-title-price a {
  text-decoration: none;
  color: #303030;
  font-size: 20px;
  font-weight: 700;
}

@media (max-width: 991.98px) {
  .grid-product-icons {
    right: 8px;
    bottom: 30px;
  }

  .grid-product-icons a {
    width: 30px;
    height: 30px;
    padding: 2px 4px;
  }

  .grid-icon {
    font-size: 1.25rem !important;
  }
}
@media (max-width: 902px) {
  .product-description {
    font-size: 16px;
  }
}

@media (max-width: 798px) {
  .product-list {
    display: flex;
  }

  .grid-product-icons {
    right: 8px;
    bottom: 20px;
  }

  .shop-product-customize {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .shop-product-customize .right {
    margin-top: 10px;
  }
  .shop-product-customize .left {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .grid-product {
    display: flex;
    align-items: center;
  }
}
@media (max-width: 661px) {
  .product-description {
    font-size: 12px;
  }

  .grid-product-icons {
    right: 0px;
    bottom: 10px;
  }

  .grid-product-icons a {
    width: 25px;
    height: 25px;
    padding: 1px 3px;
  }

  .grid-icon {
    font-size: 1rem !important;
  }
  .shopcontainer .cart-wishlist {
    margin-left: 60px;
  }
  .shopcontainer .visible-content {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 576px) {
  .grid-product {
    min-width: 100%;
  }

  .product-description {
    font-size: 16px;
  }

  .full-card .row {
    width: 100%;
  }

  .full-card .col-8 {
    width: 100%;
  }
  .full-card .select-title {
    margin: 0;
  }

  .full-card .card-content {
    padding: 0;
  }

  .card-body .product-description {
    padding: 0;
  }

  .grid-product-icons {
    right: 35px;
    bottom: 40px;
  }

  .grid-product-icons a {
    width: 45px;
    height: 45px;
    padding: 5px 3px;
  }

  .grid-icon {
    font-size: 2rem !important;
  }
  .visible-content .total-Order {
    display: flex;
    flex-direction: column;
  }
  .visible-content .total-Order .select-products-details {
    padding: 0;
  }

  .visible-content .total-Order .cart-wishlist {
    text-align: center;
    margin-left: 0px;
  }
}

@media (max-width: 421px) {
  .full-card .select-title .form-check-input {
    font-size: 20px;
  }

  .product-description {
    font-size: 14px;
  }

  .grid-product-icons {
    right: 20px;
    bottom: 20px;
  }

  .grid-product-icons a {
    width: 30px;
    height: 30px;
    padding: 2px 3px;
  }

  .grid-icon {
    font-size: 1rem !important;
  }
}
