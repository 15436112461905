* .My-Account-container {
  color: #303030;
}
.My-Account-container {
  background-color: #f4f8fb;
  padding-bottom: 5rem;
  padding-top: 2.5rem;
}
.My-Account-container .default-address {
  margin-bottom: 25px;
}

.My-Account-container .default-address .card {
  background-color: #f5fcff;
}

.My-Account-container .subcription-orders .OrderItem {
  background-color: #ffffff;
}

.My-Account-container .default-address .card,
.My-Account-container .multiple-Addresses .card {
  border-radius: 10px;
}
.My-Account-container .account-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;
}
.myaccount-modal {
  padding: 15px 30px;
}
.My-Account-container .account-heading .account-address-heading,
.My-Account-container .subcription-orders .subscription-heading {
  font-size: 2.5rem;
  font-weight: 400;
}

.My-Account-container .account-heading .Add-address-to-account .btn-dark {
  padding: 0.5rem 2rem;
  font-size: 1.5rem;
  font-weight: 300;
  border-radius: 7px;
}

.My-Account-container .default-address .card-title {
  font-size: 25px;
  margin: 0;
}

.My-Account-container .default-address .card-text {
  font-size: 25px;
  margin: 0;
  padding: 0;
  font-weight: 400;
}

.My-Account-container .default-address .billing-email {
  text-decoration: underline;
}

.My-Account-container .default-address .billing-edit-delete .btn-link,
.My-Account-container .default-address .billing-edit-delete,
.My-Account-container
  .multiple-Addresses
  .billing-edit-delete-setDefault
  .btn-link,
.My-Account-container .multiple-Addresses .billing-edit-delete-setDefault {
  color: #37afe3;
  padding: 0;
  margin-right: 1rem;
  text-decoration: none;
}

.My-Account-container .default-address .billing-edit-delete .billing-delete,
.My-Account-container
  .multiple-Addresses
  .billing-edit-delete-setDefault
  .billing-delete,
.My-Account-container
  .multiple-Addresses
  .billing-edit-delete-setDefault
  .billing-setDefault {
  margin-left: 1rem;
}

.My-Account-container .default-address .billing-edit-delete,
.My-Account-container .multiple-Addresses .billing-edit-delete-setDefault {
  margin-top: 2rem;
}

.My-Account-container .multiple-Addresses .card-parent-body {
  margin-bottom: 1.5rem;
}

.My-Account-container .default-address .card-body {
  line-height: 30px;
  padding: 2rem 2rem;
  background-color: #f5fcff;
  border-radius: 7px;
}
.My-Account-container .multiple-Addresses .card-body,
.My-Account-container
  .subcription-orders
  .subscription-orders-container
  .OrderItem {
  padding: 1.5rem 2rem;
  line-height: 24px;
  border-radius: 7px;
}
.My-Account-container
  .order-delivery-expiry-default-details {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.My-Account-container .multiple-Addresses .card-title {
  font-size: 20px;
  margin: 0;
}
.My-Account-container .multiple-Addresses .card-text {
  font-size: 20px;
  margin: 0;
  padding: 0;
  font-weight: 400;
}

.order-delivery-expiry-default-details .order-renew .btn-dark {
  padding: 5px 20px;
  font-size: 16px;
  border-radius: 7px;
  background-color: #223253;
}

.My-Account-container .multiple-Addresses .billing-phoneNumber,
.My-Account-container .multiple-Addresses .billing-email {
  margin-top: 5px;
}
.My-Account-container .default-address .billing-phoneNumber,
.My-Account-container .default-address .billing-email {
  margin-top: 7px;
  font-size: 22px;
}

.My-Account-container .subcription-orders .order-products-left img {
  margin-right: 2rem;
  width: 100px;
}

.My-Account-container .subcription-orders .wishlist-items {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 10px;
}

.My-Account-container
  .subcription-orders
  .order-products-left
  .order-product-name,
.My-Account-container .subcription-orders .order-products-right {
  font-size: 1.25rem;
  font-weight: 500;
  flex-direction: row-reverse;
}
.My-Account-container
  .subcription-orders
  .order-products-left
  .order-product-name {
  padding: 10px 0px;
  line-height: 20px;
}

.My-Account-container
  .subcription-orders
  .order-products-left
  .order-product-deliveryDate {
  font-size: 1.25rem;
}

.My-Account-container
  .subcription-orders
  .order-products-left
  .order-product-deliveryDate
  .order-highlight {
  color: rgb(6, 131, 3);
  font-weight: 500;
}

.My-Account-container .subcription-orders .wishlist-products {
  padding: 0;
}

.My-Account-container .subcription-orders .order-delivery-expiry-details {
  display: flex;
  justify-content: space-between;  
  font-size: 16px;
}

.My-Account-container .subcription-orders .order-delivery-expiry-details pre {
  font-size: 16px;
}

.My-Account-container .subcription-orders .expire-date {
  margin-left: 0px;
}
.My-Account-container .modal .billing-edit-modal-footer {
  justify-content: space-between;
}
.My-Account-container .not-symbol {
  margin: 0px 20px;
}
.My-Account-container
  .modal
  .billing-edit-modal-footer
  .Cancle-submit-btns
  .submit-edit-btn {
  margin-left: 15px;
}
.My-Account-container
  .modal
  .billing-edit-modal-footer
  .Cancle-submit-btns
  .btn {
  padding: 8px 25px;
  font-size: 20px;
  font-weight: 300;
  color: white;
  border-radius: 8px;
}
.billing-edit-modal-footer {
  padding: 15px 30px;
}

.billing-edit-modal-footer .form-check-input {
  font-size: 20px;
  margin-right: 5px;
}

.setDefault-lable {
  margin-top: 4px;
}

.My-Account-container .modal .make-me-invisible {
  position: absolute;
  right: -6px;
  top: -12px;
  background: #d21e1e;
  color: white !important;
  border-radius: 25px;
  width: 30px;
  height: 30px;
  opacity: 10 !important;
}

.My-Account-container .modal .make-me-invisible span {
  position: absolute;
  font-size: 40px;
  top: -8px;
  right: 12px;
}
.option-not-check {
  border: 1px solid red;
}
.manual-feedback {
  margin-top: 4px;
  font-size: 14px;
  color: #d21e1e;
}

.My-Account-container .modal .modal-body label span {
  color: red;
}
.My-Account-container .default-address .billing-phoneNumber {
  text-decoration: underline;
}

.EmptyMyAccount-container .Account-empty-details .emptyMyAcount-right {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #999999;
}

.EmptyMyAccount-container .Account-empty-details {
  display: flex;
  padding: 40px 0px;
  justify-content: center;
}

.react-tel-input .form-control {
  width: 100%;
}

@media (max-width: 1199px) {
  .My-Account-container .modal-address-class .example-input {
    width: 50%;
  }
  .My-Account-container .subcription-orders .order-renew {
    display: flex;
    width: 100%;
    justify-content: end;
    margin-top: 10px;
  }
}
@media (max-width: 991px) {
  .myaccount-modal {
    padding: 10px;
  }
  .myaccount-modal .row {
    --bs-gutter-x: 1rem;
  }
}
@media (max-width: 767px) {
  .My-Account-container .account-heading .account-address-heading,
  .My-Account-container .subcription-orders .subscription-heading {
    font-size: 2rem;
    font-weight: 400;
  }
  .My-Account-container .default-address .default-details {
    font-size: 1.5rem;
  }
  .My-Account-container .multiple-Addresses .details {
    font-size: 1rem;
  }
  .card-body,
  .My-Account-container .multiple-Addresses .card-body {
    padding: 1.5rem;
  }
  .My-Account-container .account-heading .Add-address-to-account .btn-dark {
    padding: 5px 15px;
  }
  .My-Account-container .multiple-Addresses .col-sm-6 {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .My-Account-container .account-heading {
    flex-wrap: wrap;
  }
  .My-Account-container .modal-address-class .example-input {
    width: 100%;
  }
  .My-Account-container .subcription-orders .delivery-date,
  .My-Account-container .subcription-orders .expire-date {
    margin: 0px;
    width: 100%;
    margin-top: 10px;
    text-align: left;
  }
}
@media (max-width: 490px) {
  .My-Account-container .not-symbol {
    margin-left: 0px;
  }
}
