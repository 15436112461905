.main {
  background-image: url("../../pages/enrichImages/banner-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.banner-content {
  padding: 2rem 0rem;
}
.left-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.highLight {
  color: #108abf;
  font-weight: 600;
  margin-top: -12px;
}
.left-content h2 {
  font-size: 50px;
}
.left-content h2,
.left-content h4 {
  font-weight: 1000;
}
.left-content p {
  font-size: 1.2rem;
}

.left-content .btn {
  padding: 10px 20px;
  font-size: 20px;
}

.left-content .btn:hover {
  color: #505050;
  border: 1px solid #e65f5f;
  background-color: #e65f5f;
}

.check-bag {
  font-size: 90px;
  padding: 10px;
}
.main .btn-danger:hover {
  color: #fff;
}

.order-card {
  background-color: #fbfcfd96;
  border: 1px solid #acdaef;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
}

.order-card-text {
  color: #505050;
  font-size: 20px;
}

@media (max-width: 991.98px) {
  .main {
    background-size: cover;
  }
  .left-content h2 {
    font-size: 40px;
  }

  .left-content,
  .right-content {
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .main .left-content,
  .main .right-content {
    align-items: center;
  }
  .main .left-content h2 {
    text-align: center;
  }
  .main .enrich-header-top-left {
    text-align: center;
  }
  .main .left-content p {
    text-align: center;
  }
}
