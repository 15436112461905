.registerLoginContainer a {
  text-decoration: none;
  color: #38afe4;
}
.registerLoginContainer {
  background-image: url("../enrichImages/register-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2rem 0rem;
}
.registerLoginContainer .headLineContainer {
  width: 100%;
}
.registerLoginContainer .login-register-heading {
  width: 100%;
}
.registerLoginContainer .loginRegisterContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.registerLoginContainer form {
  padding: 25px 80px 37px 80px;
  width: 100%;
  max-width: 580px;
  background-color: #fff;
  border-radius: 8px;
}
.registerLoginContainer .custom-button {
  background-color: #38afe4;
  color: #fff;
  font-size: 20px;
  padding: 5px 20px;
}
.registerLoginContainer .loginRegisterHead {
  font-size: 40px;
}
.registerLoginContainer .buttonsContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.login-remove-bg .form-control.is-invalid{
  background-image: none;
}
.registerLoginContainer .buttonsContainer .socialBtn {
  width: 49%;
  margin-top: 7px;
  padding: 0;
  border: none;
}
.registerLoginContainer .buttonsContainer .socialBtn .reactBtn {
  width: 100%;
}
.registerLoginContainer .buttonsContainer .socialBtn .btn {
  width: 100%;
  position: relative;
  padding: 8px 5px 8px 22px;
  overflow: hidden;
  font-size: 13px;
}
.registerLoginContainer .emailNameLabel {
  color: #404040;
}
.registerLoginContainer input::placeholder {
  font-size: 15px;
}
.registerLoginContainer .register-navigator-line {
  width: 100%;
  font-size: 20px;
  text-align: end;
  margin-bottom: 30px;
}
.registerLoginContainer .register-navigator-line svg {
  font-size: 16px;
  margin: 0px 10px;
}
.registerLoginContainer .buttonsContainer .socialBtn .btn span {
  width: 28px;
  height: 100%;
  line-height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.shift-heading svg {
  color: #827d7d;
}
.registerLoginContainer .show-password-parent {
  position: relative;
}
.registerLoginContainer .invalid-box {
  font-size: 14px;
  margin-top: 4px;
  color: #dc3545;
}
.registerLoginContainer .show-password {
  position: absolute;
  right: 20px;
  top: 41px;
  font-size: 20px;
  color: #505050;
}
/* .registerLoginContainer .login-password{

} */
.registerLoginContainer .password-move {
  border-color: #dc3545;
}
.registerLoginContainer .privacyPolicy {
  margin-left: 2px;
  background-color: #fff !important;
}
.registerLoginContainer .privacyPolicy:hover {
  text-decoration: underline;
}
.registerLoginContainer .form-control {
  padding: 12px 10px;
}
.registerLoginContainer .account-exists {
  text-align: center;
}
.registerLoginContainer .form-label {
  font-weight: 500;
}
.registerLoginContainer .forgotPassword {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.registerLoginContainer .forgotPaswordPara {
  color: #09070a;
  text-decoration: underline;
}
.registerLoginContainer .forgotPaswordPara:hover {
  text-decoration: none;
}
.registerLoginContainer .headLineContainer .home {
  color: #45bef7;
}
.registerLoginContainer .headLineContainer .home:hover {
  text-decoration: underline;
}
.socialBtn-li {
  pointer-events: none;
  opacity: 0.5;
  cursor: default;
}

hr {
  background-color: #909090;
}
.social-btn .fa-google,
.social-btn .fa-linkedin-in,
.social-btn .fa-facebook-f {
  color: #fff;
  background-color: black;
  font-size: 1.2rem;
  margin: 0px;
}
.terms p,
.terms {
  font-size: 14px;
}
.socialBtn button {
  font-size: 10px;
  font-weight: 550;
}
.registerLoginContainer .socialBtn {
  display: flex;
  justify-content: space-between;
  width: 33%;
  margin-top: 10px;
}
.registerLoginContainer .fab {
  width: 20px;
}
.socialBtn .fb-btn {
  padding: 0;
  padding-right: 4px;
  font-weight: 700;
  background: #d9e0f0;
  color: #4268b2;
}
.registerLoginContainer .shop-product-heading {
  width: 100%;
  display: flex;
  justify-content: right;
}
.socialBtn .facebook-btn {
  background-color: #4268b2;
  margin-right: 5px;
}
.socialBtn .google-btn {
  margin-right: 5px;
  background-color: #de4b38;
}
.socialBtn .linkedIn-btn {
  margin-right: 5px;
  background-color: #017bb6;
}
.socialBtn .ggl-btn {
  background-color: #f8dbd7;
  color: #de4b38;
}
.socialBtn .li-btn {
  color: #017bb6;
  background-color: #cce6f2;
}
.socialBtn .btn {
  font-size: 0.7rem;
}
.emailNameContainer .btn-info {
  color: #fff;
  background-color: #38afe4;
  font-size: 20px;
}
.socialBtn i {
  color: #fff;
  font-size: 15px;
  font-weight: 550;
}
.registerLoginContainer .register-navigator-line .home:hover {
  color: #178cbf;
  text-decoration: none;
  text-decoration: underline;
}
/*The below CSS is for Register PopUp */

.register-popup-container .border-bottom-0 {
  padding: 0px;
}
.register-popup-container .btn-green {
  padding: 5px 20px;
  background-color: #35ab73;
  color: white;
}
.registerLoginContainer .registerLoginNow {
  color: #45bef7;
  font-size: 20px;
}
.register-popup-container .modal-header .btn-close {
  position: absolute;
  right: -8px;
  top: -8px;
  font-size: 20px;
  padding: 5px;
  width: 30px;
  height: 30px;
  text-align: center;
  background: none;
  background-color: #d21e1f;
  color: #fff;
  border-radius: 50px;
  opacity: 1;
}
.modal-btns .modal-button-ok {
  padding: 7px 20px;
}
.popup-container-success .modal-mid-text {
  padding: 0px 20px;
}
.register-popup-container .modal-header .btn-close:hover {
  background-color: #bf1a1b;
}

@media (max-width: 991px) {
  .registerLoginContainer .buttonsContainer {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .registerLoginContainer form {
    padding: 25px 50px;
  }
}
@media (max-width: 617px) {
  .registerLoginContainer form {
    padding: 25px 20px;
  }
}
@media (max-width: 518px) {
  .registerLoginContainer .buttonsContainer {
    width: 100%;
    text-align: center;
  }
  .registerLoginContainer .socialBtn {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .registerLoginContainer .ggl-btn {
    padding-right: 16px;
  }
  .registerLoginContainer .li-btn {
    padding-right: 10px;
  }
}
@media (max-width: 479px) {
  .registerLoginContainer .buttonsContainer .socialBtn {
    width: 100%;
  }
}
@media (max-width: 340px) {
  .registerLoginContainer .forgotPassword {
    justify-content: center;
    flex-wrap: wrap;
  }
  .registerLoginContainer .loginRegisterHead {
    font-size: 30px;
  }
}
